<template>
  <div class="actCredentials">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">账号凭证</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div class="content">
        <div class="mask">
          <div class="qrBorder">
            <div class="qrBox">
              <div ref="qrCodeUrl"></div>
            </div>
          </div>
          <div class="credentialTitle">账号凭证</div>
          <div class="uid">
            涩漫ID &nbsp;<span>{{ userInfo.uid }}</span>
          </div>
          <div class="tip">我的-设置-账号找回-账号凭证</div>
          <div class="officialUrl" @click="copyUrl">
            永久官网地址 {{ userInfo.promoteURL }}
          </div>
        </div>
      </div>

      <div class="saveBtn" @click="save">保存账号凭证</div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { Toast } from "vant";
import { queryCertificate } from "@/api/user";
export default {
  name: "actCredentials",
  components: {},
  data() {
    return {
      userInfo: "",
    };
  },
  created() {
    this.$store.dispatch("user/getUserInfo");
    this.userInfo = this.$store.getters.userInfo;
    this.queryCertificate();
    // this.generateCode();
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    async queryCertificate() {
      let ret = await this.$Api(queryCertificate);
      if (ret && ret.code == 200) {
        this.qrUrl = ret.data.content;
        this.generateCode(ret.data.content);
      }
    },
    // 生成二维码
    generateCode(url) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 135,
          height: 135,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    // 复制链接
    copyUrl() {
      this.$copyText(this.userInfo.promoteURL).then(
        (e) => {
          Toast("复制成功");
        },
        (e) => {
          Toast("复制失败");
        }
      );
    },
    save() {
      Toast("请手动截图保存账号凭证");
    },
  },
};
</script>

<style lang="scss" scoped>
.actCredentials {
  height: 100%;
  // background: #f5f5f5;
  .main {
    height: 100%;
    overflow-y: auto;
  }

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .content {
    margin: 18px 12px 0px;
    height: 571px;
    box-sizing: border-box;
    background: url("../../../assets/png/qrBg.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    border-radius: 12px;

    .appName {
      width: 54px;
      height: 28px;
      margin: 18px 0 0 18px;
    }
    .mask {
      width: 100%;
      height: 501px;
      overflow: hidden;
      // border-radius: 12px;
      margin-top: 70px;
      background: linear-gradient(
        180deg,
        rgba(245, 245, 245, 0) 0%,
        #f4f4f4 100%
      );
      .qrBorder {
        width: 174px;
        height: 174px;
        margin: 172px auto 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../assets/png/qrBox.png");
        background-size: 100% 100%;
      }
    }

    .qrBox {
      width: 154px;
      height: 154px;
      // margin: 274px auto 21px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .credentialTitle {
      font-size: 18px;
      // color: #37e7d2;
      text-align: center;
      margin-bottom: 6px;
    }
    .uid {
      text-align: center;
      font-size: 14px;
      color: #999;
      margin-bottom: 5px;
      span {
        color: #fc4162;
      }
    }

    .tip {
      margin-bottom: 9px;
      text-align: center;
      color: #999;
    }

    .officialUrl {
      text-align: center;
      font-size: 14px;
      color: #999;
    }
  }

  .saveBtn {
    width: 284px;
    height: 46px;
    background: #fbd13d;
    border-radius: 44px;
    line-height: 46px;
    font-size: 18px;
    margin: 31px auto 76px;
    color: #424242;
    text-align: center;
    // 244, 208, 75
  }
}
</style>
